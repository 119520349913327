
<transition name="modal">
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container" :style="{ 'max-width': `${width}px`, padding: `${padding}px` }">
        <div v-if="!hideClose" :class="iframe ? 'text-right close-icon' : 'text-right'">
          <button class="close" @click="$emit('close')">
            <i class="fas fa-times collapse-icon" title="Toggle" />
          </button>
        </div>
        <slot />
      </div>
    </div>
  </div>
</transition>
